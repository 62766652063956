<template>
  <div class="notification-message">
    <router-link
      :to="getUrl(notification)"
      class="notification-message__notification"
      :class="{
        'notification-message__notification--new-notification': !notification.is_seen,
      }"
    >
      <span>{{ $t(getNotificationText(notification.type), notification.context, 1) }}</span>
      <span class="notification-message__notification-date">
        {{ $filters.formatDate(notification.created) }}
      </span>
    </router-link>
    <hr class="notification-message__divider">
  </div>
</template>

<script src="./notificationMessage.js"></script>

<style lang="scss">
  .notification-message {
    display: block;
    span {
      font-weight: $font-weight-base;
      color: $neutral-80;
    }
    &__notification {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
    }
    &__notification-date {
      align-self: flex-end;
    }
    &__divider {
      width: 100%;
      height: .1rem;
      background-color: $neutral-20;
      border: none;
      margin: 0;
    }
    &__notification--new-notification span {
      font-weight: $font-weight-semi-bold;
      color: $neutral-80;
    }
  }
</style>
