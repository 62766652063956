import axios from 'Services/api/base';
import urls from 'Services/api/endpoints';

import roles from '@/constants/roles';
import validQueries from '@/constants/validQueries';

import { loadLanguageAsync } from '@/i18n';

import {
  addResponseToRefiner,
  identifyRefinerUser,
} from 'Services/refiner/refiner';

export const LOGIN = 'LOGIN';
export const REMOVE_QUERY_PARAMS = 'REMOVE_QUERY_PARAMS';
export const SET_SSO_DATA = 'SET_SSO_DATA';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_DATA_STORAGE_AGREEMENT = 'SET_DATA_STORAGE_AGREEMENT';
export const SET_HAS_CARE_PLAN_INVITATIONS = 'SET_HAS_CARE_PLAN_INVITATIONS';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_HAS_MONITOR_REQUESTS = 'SET_HAS_MONITOR_REQUESTS';
export const SET_HAS_EMAIL_NOTIFICATIONS_ENABLED = 'SET_HAS_EMAIL_NOTIFICATIONS_ENABLED';
export const SET_QUERIES = 'SET_QUERIES';
export const SET_PARAMS = 'SET_PARAMS';
export const UPDATE_EMAIL_ADDRESS = 'UPDATE_EMAIL_ADDRESS';

export const initialStateAuth = () => ({
  user: {
    roles: {},
  },
  queries: {},
  params: {},
  ssoData: {},
});

const getRoles = (userRoles) => {
  const defaultRoles = Object.values(roles);
  return defaultRoles.filter((role) => userRoles[role]);
};

export const auth = {
  namespaced: true,

  state: initialStateAuth(),

  getters: {
    acceptedDataStorageAgreement: (state) => state.user.acceptedDataStorageAgreement,
    hasCarePlanInvitations: (state) => state.user.hasCarePlanInvitations,
    hasCoachingEnabled: (state) => state.user.hasCoachingEnabled,
    hasEmailNotificationsEnabled: (state) => state.user.hasEmailNotificationsEnabled,
    hasMonitorRequests: (state) => state.user.hasMonitorRequests,
    hasSelfHelpOrganisationSlugRegistration: (state) => !!state.queries.organisation_name,
    isMigrated: (state) => state.user.isMigrated,
    isOrganisationAdmin: (state) => state.user.roles[roles.ORGANISATION_ADMIN],
    isOrganisationCareGiver: (state) => state.user.roles[roles.ORGANISATION_CARE_GIVER],
    isPatient: (state) => state.user.roles[roles.PATIENT],
    isProfessional: (state) => state.user.roles[roles.PROFESSIONAL],
    params: (state) => state.params,
    query: (state) => state.queries,
    roles: (state) => getRoles(state.user.roles),
    selfHelpOrganisationName: (state) => state.queries.organisation_name,
    selfHelpOrganisationSlug: (state) => state.queries.organisation_slug,
    ssoData: (state) => state.ssoData,
    twoFactorEnabled: (state) => state.user.twoFactorEnabled,
    user: (state) => state.user,
    userEmail: (state) => state.user.email,
    userId: (state) => state.user.id,
    userName: (state) => state.user.userName,
    userRoles: (state) => state.user.roles,
    userUuid: (state) => state.user.uuid,
  },

  mutations: {
    LOGIN: (state, payload) => {
      localStorage.setItem('lang', payload.language);
      state.user = {
        ...state.user,
        acceptedDataStorageAgreement: payload.accepted_data_storage_agreement,
        email: payload.email,
        groups: payload.groups,
        hasCarePlanInvitations: payload.has_care_plan_invitations,
        hasCoachingEnabled: payload.coaching_enabled,
        hasEmailNotificationsEnabled: payload.email_notifications,
        hasMonitorRequests: payload.has_monitor_requests,
        id: payload.id,
        isMigrated: payload.is_migrated,
        roles: {
          [roles.PROFESSIONAL]: payload.groups.includes('professional'),
          [roles.PATIENT]: payload.groups.includes('patient'),
        },
        twoFactorEnabled: payload.two_factor_enabled,
        userName: payload.display_name,
        uuid: payload.uuid,
      };
    },

    SET_SSO_DATA: (state, payload) => {
      state.ssoData = { ...payload };
    },

    SET_USER_ROLES: (state, payload) => {
      state.user.roles = {
        ...state.user.roles,
        [roles.ORGANISATION_CARE_GIVER]: payload.is_organisation_care_giver,
        [roles.ORGANISATION_ADMIN]: payload.is_organisation_admin,
      };
    },

    SET_HAS_CARE_PLAN_INVITATIONS: (state, payload) => {
      state.user.hasCarePlanInvitations = payload;
    },

    SET_USER_NAME: (state, payload) => {
      state.user.userName = payload;
    },

    SET_HAS_MONITOR_REQUESTS: (state, payload) => {
      state.user.hasMonitorRequests = payload;
    },

    SET_HAS_EMAIL_NOTIFICATIONS_ENABLED: (state, payload) => {
      state.user.hasEmailNotificationsEnabled = payload;
    },

    SET_DATA_STORAGE_AGREEMENT: (state, payload) => {
      state.user.acceptedDataStorageAgreement = payload;
    },

    SET_QUERIES: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        if (validQueries.includes(key)) state.queries[key] = payload[key];
      });
    },

    SET_PARAMS: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        if (validQueries.includes(key)) state.params[key] = payload[key];
      });
    },

    REMOVE_QUERY_PARAMS: (state, payload) => {
      payload.forEach((query) => {
        delete state.queries[query];
        delete state.params[query];
      });
    },

    UPDATE_EMAIL_ADDRESS: (state, payload) => {
      state.user.email = payload;
    },
  },

  actions: {
    login: ({ dispatch }, { body, care_plan_token }) => axios
      .post(urls('login'), body, { params: { care_plan_token } })
      .then((response) => dispatch('setLoginData', response))
      .catch((error) => { throw error; }),

    setSsoData: ({ commit }, data) => {
      commit(SET_SSO_DATA, data);
    },

    resetSsoData: ({ commit }) => {
      commit(SET_SSO_DATA, {});
    },

    setLoginData: ({ commit }, response) => {
      const { data } = response;
      const is_authenticated = !data.two_factor_enabled;
      const payload = { ...data, is_authenticated };
      loadLanguageAsync(payload.language);
      commit(LOGIN, payload);

      identifyRefinerUser({
        id: payload.uuid,
        role: payload.groups[0],
        locale: payload.language,
        current_platform: window.location.hostname,
      });

      addResponseToRefiner();
    },

    setQueries: ({ commit }, queries) => {
      commit(SET_QUERIES, queries);
    },

    setParams: ({ commit }, queries) => {
      commit(SET_PARAMS, queries);
    },

    removeQueryParams({ commit }, queries) {
      commit(REMOVE_QUERY_PARAMS, queries);
    },

    setUserRoles: ({ commit }, body) => {
      commit(SET_USER_ROLES, body);
    },

    changeEmail: ({ commit }, email) => {
      commit(UPDATE_EMAIL_ADDRESS, email);
    },

    setDataStorageAgreement: ({ commit }, dataStorageAgreement) => {
      commit(SET_DATA_STORAGE_AGREEMENT, dataStorageAgreement);
    },

    setHasCarePlanInvitations: ({ commit }, hasCarePlanInvitations) => {
      commit(SET_HAS_CARE_PLAN_INVITATIONS, hasCarePlanInvitations);
    },

    setUserName: ({ commit }, userName) => {
      commit(SET_USER_NAME, userName);
    },

    setHasMonitorRequests: ({ commit }, hasMonitorRequests) => {
      commit(SET_HAS_MONITOR_REQUESTS, hasMonitorRequests);
    },

    setHasEmailNotificationEnabled: ({ commit }, hasEmailNotificationsEnabled) => {
      const payload = {
        profile: {
          email_notifications: hasEmailNotificationsEnabled,
        },
      };

      axios.patch(urls('settings'), payload)
        .then(() => {
          commit(SET_HAS_EMAIL_NOTIFICATIONS_ENABLED, hasEmailNotificationsEnabled);
        }).catch((error) => {
          throw error;
        });
    },

    twoFactorAuthenticate: ({ commit }, body) => axios
      .post(urls('two_factor_authenticate'), body)
      .then((response) => {
        const payload = { ...response.data, is_authenticated: true };
        commit(LOGIN, payload);
      }).catch((error) => {
        throw error;
      }),
  },
};
