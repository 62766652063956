import { mapGetters } from 'vuex';

import urls from 'Services/api/endpoints';

import isDeployed from '@/helpers/isDeployed';
import isAuth from '@/helpers/isAuth';
import carePlanStatus from '@/constants/carePlanStatus';
import carePlanCreationTypes from '@/constants/carePlanCreationTypes';

const totalAttempts = 3;

export default {
  computed: {
    ...mapGetters('auth', ['userId', 'userUuid', 'roles', 'isProfessional', 'hasCoachingEnabled']),
    ...mapGetters('enrollments', ['activeEnrollment', 'activeOrganisation']),
    async pendoConfig() {
      let pendoConfig = {
        // https://agent.pendo.io/advanced/location/#available-methods
        location: {
          transforms: [
            {
              attr: 'search',
              action: 'ExcludeKeys',
              data() {
                return ['auth_code', 'token'];
              },
            },
          ],
        },
      };
      if (isAuth()) {
        pendoConfig = this.setGlobalConfig(pendoConfig);
        if (this.isProfessional) pendoConfig = this.setProfessionalConfig(pendoConfig);
        else pendoConfig = await this.setPatientConfig(pendoConfig);
      }
      return pendoConfig;
    },

    isPendoReady() {
      return this.$pendo.isReady();
    },
  },
  data() {
    return {
      timeoutId: undefined,
      numAttempts: 0,
    };
  },

  methods: {
    setGlobalConfig(config) {
      return {
        ...config,
        visitor: {
          id: this.userUuid,
          roles: this.roles,
          account_language: localStorage.getItem('lang') || 'nl',
          products: [
            this.hasCoachingEnabled ? 'coaching' : '',
            this.activeOrganisation.has_self_start_treatments ? 'self_start_treatments' : '',
            this.activeEnrollment.questionnaires_enabled && this.isProfessional ? 'questionnaires' : '',
            this.activeEnrollment.treatments_paid_count > 0 && this.isProfessional ? 'treatments' : '',
            this.activeEnrollment.external_video_call_enabled && this.isProfessional ? 'video_call' : '',
          ].filter(Boolean),
          label: import.meta.env.VITE_BRAND,
        },
      };
    },

    async setPatientConfig(config) {
      const {
        self_start_care_plan_count = 0,
        self_help_care_plan_count = 0,
        blended_care_plan_count = 0,
        unknown_type_care_plan_count = 0,
        organization_uuid = [],
        organization_names = [],
        organization_markets = [],
      } = await this.getCarePlansData();

      return {
        ...config,
        visitor: {
          ...config.visitor,
          // Care plan types
          self_start_care_plan_count,
          self_help_care_plan_count,
          blended_care_plan_count,
          unknown_type_care_plan_count,
          // Organisations data
          organization_uuid,
          organization_names,
          organization_markets,
        },
      };
    },

    setProfessionalConfig(config) {
      return {
        ...config,
        account: {
          id: isDeployed() ? this.activeOrganisation.id : 'development',
          organisation_name: this.activeOrganisation.name,
          market: this.activeOrganisation.market,
        },
      };
    },

    async attemptInitPendo() {
      if (this.isPendoReady) this.$pendo.identify(await this.pendoConfig);
      else this.$pendo.initialize(await this.pendoConfig);
    },

    attemptTrack(name, data) {
      if (this.isPendoReady) this.$pendo.track(name, data);
    },

    async getCarePlansData() {
      const { data } = await this.$axios.get(urls('care_plan_patient', this.userId), this.$setParamsList({
        care_taker_status: [carePlanStatus.ACCEPTED, carePlanStatus.ARCHIVED],
      }, 'repeat'));

      const { organisations, carePlanTypes } = data.results
        .reduce((acc, { organisation, creation_type }) => {
          acc.organisations.push(organisation);
          acc.carePlanTypes.push(creation_type);
          return acc;
        }, { organisations: [], carePlanTypes: [] });
      return {
        ...this.parseOrganisationsData(organisations),
        ...this.parseCreationTypesCount(carePlanTypes),
      };
    },

    parseCreationTypesCount(carePlanTypes) {
      return carePlanTypes.reduce((counts, type) => {
        switch (type) {
          case carePlanCreationTypes.SELF_START:
            counts.self_start_care_plan_count += 1;
            break;
          case carePlanCreationTypes.SELF_HELP:
            counts.self_help_care_plan_count += 1;
            break;
          case carePlanCreationTypes.BLENDED:
            counts.blended_care_plan_count += 1;
            break;
          default:
            counts.unknown_type_care_plan_count += 1;
            break;
        }
        return counts;
      }, {
        self_start_care_plan_count: 0,
        self_help_care_plan_count: 0,
        blended_care_plan_count: 0,
        unknown_type_care_plan_count: 0,
      });
    },

    parseOrganisationsData(organisations) {
      return organisations.reduce((out, organisation) => {
        out.organization_uuid.push(organisation.uuid);
        out.organization_names.push(organisation.name);
        out.organization_markets.push(organisation.market);
        return out;
      }, {
        organization_uuid: [],
        organization_names: [],
        organization_markets: [],
      });
    },

    resetAttempts() {
      this.numAttempts = 0;
    },

    async handlePendoAction(action, errorMessage, actionArgs = []) {
      if (!import.meta.env.VITE_PENDO) return;
      try {
        this.numAttempts += 1;
        await action(...actionArgs);
        this.resetAttempts();
      } catch (e) {
        clearTimeout(this.timeoutId);
        if (this.numAttempts <= totalAttempts) {
          const retryAction = () => this.handlePendoAction(action, errorMessage, actionArgs);
          this.timeoutId = setTimeout(retryAction, 1000);
          return;
        }

        console.warn(`Pendo failed to ${errorMessage}: ${e.message}\n\nTotal attempts reached.`);
      }
    },

    initializePendo() {
      this.handlePendoAction(this.attemptInitPendo, 'load', []);
    },

    pendoTrackEvent(name, data) {
      this.handlePendoAction(this.attemptTrack, `track event: ${name}`, [name, data]);
    },
  },
};
