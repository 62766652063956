import qs from 'qs';
import moment from 'moment';
import { vOnClickOutside } from '@vueuse/components';
import axios from 'Services/api/base';
import i18n from '@/i18n';
import sanitize from '@/helpers/sanitize';

const injectSanitizedValue = (el, value) => {
  const valueSanitized = sanitize(value);
  if (valueSanitized) el.innerHTML = valueSanitized;
  else el.innerText = value;
};

export const localPlugins = {
  install(app) {
    app.config.globalProperties.$setParamsList = (params, format) => ({
      //  FORMAT[str] TYPES
      // 'indices' => 'a[0]=b&a[1]=c'
      // 'brackets' => 'a[]=b&a[]=c'
      // 'repeat' => 'a=b&a=c'
      // 'comma' => 'a=b,c'
      params,
      paramsSerializer: (paramSerializer) => qs.stringify(paramSerializer, { arrayFormat: format }),
    });
    app.config.globalProperties.$filters = {
      formatDate: (date, format = 'DD/MM/YYYY', fallback = '') => {
        if (!date) return fallback || i18n.global.t('general.words.unknown');
        return moment(date).format(format);
      },
    };
    app.config.globalProperties.$axios = axios;
    app.config.globalProperties.$t = i18n.global.t;
    if (import.meta.env.VITE_PENDO) app.config.globalProperties.$pendo = window.pendo;
  },
};

export const setDirectives = (app) => {
  app.directive('sanitize-html', {
    updated(el, { value }) { injectSanitizedValue(el, value); },

    beforeMount(el, { value }) { injectSanitizedValue(el, value); },
  });
  app.directive('click-outside', vOnClickOutside);
};
